import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { AuthenticationService, MIAUser } from '@mobileia/authentication';
import { Router } from '@angular/router';
import { LayoutMenuService } from '../services/layout-menu.service';

@Component({
  selector: 'mia-layout-elite-topbar',
  template: `<!-- ============================================================== -->
<!-- Topbar header - style you can find in pages.scss -->
<!-- ============================================================== -->
<header class="topbar" (window:resize)="onResize($event)">
    <nav class="navbar top-navbar navbar-expand-md navbar-dark">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" href="">
                <!-- Logo icon --><b>
                          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Light Logo icon -->
                    <img *ngIf="isMiniSidebar" [src]="logoIcon" alt="homepage" class="light-logo">
                      </b>
                <!--End Logo icon -->
                <!-- Logo text -->
                <span>
                 <!-- Light Logo text -->    
                 <img *ngIf="!isMiniSidebar" [src]="logoText" class="light-logo" alt="homepage">
                </span>
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <ul class="navbar-nav mr-auto">
                <!-- This is  -->
                <li class="nav-item"><a class="nav-link nav-toggler d-block d-sm-none waves-effect waves-dark" (click)="clickOpenSidebar()"><i class="fa fa-bars"></i></a> </li>
                <!--<li class="nav-item"> <a class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" href="javascript:void(0)"><i class="fa fa-bars"></i></a>-->
            </ul>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">
                <!-- ============================================================== -->
                <!-- User Profile -->
                <!-- ============================================================== -->
                <li ngbDropdown class="nav-item dropdown u-pro" placement="bottom-right">
                    <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark profile-pic" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="assets/images/user/1.jpg" alt="user" class=""> <span class="hidden-md-down"> {{ currentUser.firstname }} &nbsp;<i class="fa fa-angle-down"></i></span>
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right animated flipInY">
                        <div *ngFor="let item of menuItems">
                            <a *ngIf="!item.separator" href="javascript:void(0)" (click)="clickItemMenu(item.id)" class="dropdown-item"><i class="{{ item.icon }}"></i> {{ item.titleÂ }}</a>
                            <div *ngIf="item.separator" class="dropdown-divider"></div>
                        </div>
                    </div>

                </li>
                <!-- ============================================================== -->
                <!-- End User Profile -->
                <!-- ============================================================== -->
            </ul>
        </div>
    </nav>
</header>`,
  styles: [`.navbar-brand img{max-height:40px}.light-logo{max-height:35px}`]
})
export class TopbarComponent implements OnInit {

  @Input() logoIconDark: String = '';
  @Input() logoIcon: String = '';
  @Input() logoTextDark: String = '';
  @Input() logoText: String = '';
  @Input() menuItems = [];
  isMiniSidebar = false;

  currentUser: MIAUser = new MIAUser();

  constructor(private authService: AuthenticationService,
    private router: Router,
    private menuService: LayoutMenuService,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.currentUser.firstname = 'Anonimo';
    // Buscar usuario logueado
    this.authService.getCurrentUser().subscribe(user => {
      if (user == null) {
        return;
      }
      this.currentUser = user;
    });
  }

  public clickItemMenu(id: number) {
    this.menuService.emitTopbarClick(id);
  }

  public clickOpenSidebar() {
    // Obtener body
    const body = document.getElementsByTagName('body')[0];
    // recorremos clases
    for (var i = 0; i < body.classList.length; i++) {
      // tslint:disable-next-line:triple-equals
      if (body.classList[i] == 'show-sidebar') {
        this.renderer.removeClass(document.body, 'show-sidebar');
        return;
      }
    }
    this.renderer.addClass(document.body, 'show-sidebar');
  }

  public onResize(event) {
    if (event.target.innerWidth < 1170) {
      this.isMiniSidebar = true;
      this.renderer.addClass(document.body, 'mini-sidebar');
    } else {
      this.isMiniSidebar = false;
      this.renderer.removeClass(document.body, 'mini-sidebar');
    }
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterContentInit(): void {
    if (window.innerWidth < 1170) {
      this.isMiniSidebar = true;
      this.renderer.addClass(document.body, 'mini-sidebar');
    } else {
      this.isMiniSidebar = false;
      this.renderer.removeClass(document.body, 'mini-sidebar');
    }
  }
}
