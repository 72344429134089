import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mia-layout-elite-page-header',
  template: `<div class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h4 class="text-themecolor">{{Â title }}</h4>
    </div>
    <div class="col-md-7 align-self-center text-right">
        <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Inicio</a></li>
                <li class="breadcrumb-item active">{{Â title }}</li>
            </ol>
            <!--<button type="button" class="btn btn-info d-none d-lg-block m-l-15"><i class="fa fa-plus-circle"></i> Create New</button>-->
        </div>
    </div>
</div>`,
  styles: [`.page-titles .breadcrumb .breadcrumb-item+.breadcrumb-item::before{content:">"}`]
})
export class PageHeaderComponent implements OnInit {

  @Input() title : String = "";
  @Input() breadcrumb = [];

  constructor() { }

  ngOnInit() {
  }

}
