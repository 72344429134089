import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mia-layout-elite-old',
  template: `<p>
    layout-elite works!
</p>
<router-outlet></router-outlet>
<h2>Footer</h2>`,
  styles: []
})
export class LayoutEliteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
