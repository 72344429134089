import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'mia-dynamic-form',
  template: `<form (ngSubmit)="onSend.emit(this.form.value)" [formGroup]="form" class="form-horizontal">
    <div *ngFor="let field of fields">
        <mia-element-builder [field]="field" [form]="form"></mia-element-builder>
    </div>
    <!--<div class="form-row"></div>
    <div class="form-group">
        <button type="submit" [disabled]="!form.valid" class="btn btn-primary">Save</button>
    </div>-->
</form>`,
  styles: [``]
})
export class MiaDynamicFormComponent implements OnInit {

  @Output() onSend = new EventEmitter();
  @Input() fields: any[] = [];
  @Input() form: FormGroup;

  constructor() { }

  ngOnInit() {

  }

}
