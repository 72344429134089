import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LayoutMenuService } from '../services/layout-menu.service';

@Component({
  selector: 'mia-layout-elite-sidebar',
  template: `<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <div *ngFor="let item of menuItems">
                    <li *ngIf="item.is_group" class="nav-small-cap">{{ item.titleÂ }}</li>
                    <li *ngIf="!item.is_group" [ngClass]="{'active':item.is_active == true}">
                        <a (click)="clickItemMenu(item)" class="has-arrow waves-effect waves-dark" [ngClass]="{'active':item.is_active == true}" href="javascript:void(0)" aria-expanded="false"><i class="{{ item.icon }}"></i><span class="hide-menu"> {{ item.titleÂ }} <span *ngIf="item.budge" class="badge badge-pill badge-cyan ml-auto" style="float:right">{{ item.budgeÂ }}</span></span></a>
                    </li>
                </div>
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>`,
  styles: [``]
})
export class SidebarComponent implements OnInit {

  @Input() menuItems = [];

  constructor(private menuService : LayoutMenuService) { 
    
  }

  ngOnInit() {
    this.menuService.getActiveSidebarObservable().subscribe(id => {
      this.cleanActive();
      this.activate(id);
    });
  }

  public clickItemMenu(item){
    this.cleanActive();
    item.is_active = true;
    this.menuService.emitSidebarClick(item.id);
  }

  cleanActive(){
    this.menuItems.forEach(element => {
      element.is_active = false;
    });
  }

  activate(id : number){
    this.menuItems.forEach(element => {
      if(element.id == id){
        element.is_active = true;
      }
    });
  }
}
