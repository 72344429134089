import { Component, OnInit, Input } from '@angular/core';
import { MIAFilter } from '../entity/filter.entity';
import { LayoutTableService } from '../services/layout-table.service';

@Component({
  selector: 'mia-layout-elite-table',
  template: `<table class="table table-striped">

    <thead>
        <tr>
            <th *ngFor="let item of th" (click)="onClickFilter(item)" class="{{ item.class }}" [ngStyle]="{'cursor':item.filter != undefined ? 'pointer' : 'normal' }">
                {{ item.title }}
                <i *ngIf="item.filter != undefined && filter.title == item.filter && filter.asc == 1" class="fa fa-long-arrow-alt-up"></i>
                <i *ngIf="item.filter != undefined && filter.title == item.filter  && filter.asc == 0" class="fa fa-long-arrow-alt-down"></i>
            </th>
        </tr>
    </thead>

    <ng-content select="tbody"></ng-content>
</table>`,
  styles: [``]
})
export class TableComponent implements OnInit {

  @Input() th = [];
  @Input() filter : MIAFilter = new MIAFilter();

  constructor(private tableService : LayoutTableService) { }

  ngOnInit() {
  }

  onClickFilter(item){
    if(item.filter == '' || item.filter == undefined){
      return;
    }
    if(this.filter.title == this.filter.title){
      if(this.filter.asc == 1){
        this.filter.asc = 0;
      }else{
        this.filter.asc = 1;
      }
    }else{
      this.filter.asc = 1;
    }
    this.filter.title = item.filter;
    this.tableService.emitFilter(this.filter);
  }

}
