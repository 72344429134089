import { Component, OnInit } from '@angular/core';
import { MiaBaseElementComponent } from '../mia-base-element';

@Component({
  selector: 'mia-button-element',
  template: `<div [formGroup]="form">
    <button type="submit" [disabled]="!form.valid" class="btn btn-primary">{{field.label}}</button>
</div>`,
  styles: [``]
})
export class MiaButtonElementComponent extends MiaBaseElementComponent {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
