import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mia-checkbox-element',
  template: `<div [formGroup]="form">
    <div [formGroupName]="field.name">
        <div *ngFor="let opt of field.options" class="form-check form-check">
            <label class="form-check-label">
       <input [formControlName]="opt.key" class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
       {{opt.label}}</label>
        </div>
    </div>
</div>`,
  styles: [``]
})
export class MiaCheckboxElementComponent implements OnInit {

  @Input() field:any = {};
    @Input() form:FormGroup;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }

  constructor() { }

  ngOnInit() {
  }

}
