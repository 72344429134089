import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mia-select-element',
  template: `<div [formGroup]="form">
    <select class="form-control" [id]="field.name" [formControlName]="field.name">
    <option *ngFor="let opt of field.options" [value]="opt.key">{{opt.label}}</option>
  </select>
</div>`,
  styles: [``]
})
export class MiaSelectElementComponent implements OnInit {

  @Input() field:any = {};
  @Input() form:FormGroup;

  constructor() { }

  ngOnInit() {
  }

}
