import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mia-element-builder',
  template: `<div class="form-group" [formGroup]="form">
    <label *ngIf="field.type != 'button'" class="form-control-label" [attr.for]="field.label">
        {{field.label}}
        <strong class="text-danger" *ngIf="field.required">*</strong>
    </label>
    <div class="" [ngSwitch]="field.type">
        <mia-text-element *ngSwitchCase="'text'" [field]="field" [form]="form"></mia-text-element>
        <mia-select-element *ngSwitchCase="'select'" [field]="field" [form]="form"></mia-select-element>
        <mia-checkbox-element *ngSwitchCase="'checkbox'" [field]="field" [form]="form"></mia-checkbox-element>
        <mia-radio-element *ngSwitchCase="'radio'" [field]="field" [form]="form"></mia-radio-element>
        <mia-filezone-element *ngSwitchCase="'filezone'" [field]="field" [form]="form"></mia-filezone-element>
        <mia-button-element *ngSwitchCase="'button'" [field]="field" [form]="form"></mia-button-element>
        <div class="alert alert-danger my-1 p-2 fadeInDown animated" *ngIf="!isValid && isDirty">{{field.label}} is required</div>
    </div>
</div>`,
  styles: [``]
})
export class MiaElementBuilderComponent implements OnInit {

  @Input() field:any;
  @Input() form: FormGroup;
  
  get isValid() { return this.form.controls[this.field.name].valid; }
  get isDirty() { return this.form.controls[this.field.name].dirty; }

  constructor() { }

  ngOnInit() {
  }

}
