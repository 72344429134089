import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mia-layout-elite',
  template: `<div id="main-wrapper">
    <mia-layout-elite-topbar *ngIf="isLogged" [menuItems]="topbarMenu" [logoText]="logoText" [logoTextDark]="logoTextDark" [logoIcon]="logoIcon" [logoIconDark]="logoIconDark"></mia-layout-elite-topbar>
    <mia-layout-elite-sidebar *ngIf="isLogged" [menuItems]="sidebarMenu"></mia-layout-elite-sidebar>

    <div class="" [ngClass]="{'page-wrapper': isLogged}">
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>`,
  styles: [``]
})
export class LayoutComponent implements OnInit {

  title = 'app';

  @Input() isLogged = false;

  @Input() logoIconDark: String = '';
  @Input() logoIcon: String = '';
  @Input() logoTextDark: String = '';
  @Input() logoText: String = '';
  @Input() topbarMenu = [];
  @Input() sidebarMenu = [];

  constructor() {
  }

  ngOnInit() {
  }
}
