import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mia-radio-element',
  template: `<div [formGroup]="form">
    <div class="form-check" *ngFor="let opt of field.options">
        <input [id]="opt.key" class="form-check-input" type="radio" [value]="opt.key" [formControlName]="field.name">
        <label class="form-check-label" for="{{ opt.keyÂ }}">
      {{opt.label}}
    </label>
    </div>
</div>`,
  styles: [``]
})
export class MiaRadioElementComponent implements OnInit {

  @Input() field:any = {};
  @Input() form:FormGroup;

  constructor() { }

  ngOnInit() {
  }

}
